// JavaScript Document

new WOW().init();

/*****************************
		Scroll bar
*****************************/

/* $(document).ready(function() {
	var nice = $("html").niceScroll(); 
		$("#boxscroll").niceScroll({
			cursorborder:"",
			cursorcolor:"#aaa",
			scrollspeed: 60,
			touchbehavior:false,
			boxzoom:false
		
		}); 
  }); */
 
/******************************************************
		On scroll add remove class in setting page Panel
*******************************************************/

 
// $(document).ready(function() {
//   $('.main-content').scroll(function(){
//       if ($(this).scrollTop() > 50) {
//          $('.topfixed__bar').addClass('sticky');
//       } else {
//          $('.topfixed__bar').removeClass('sticky');
//       }
//   });
// });


/******************************************************
	Anchor click smothscroll page
*******************************************************/

// $(document).ready(function() {
//     // Click event for any anchor tag that's href starts with #
//     $('a.smothscroll').click(function(event) {

//         // The id of the section we want to go to.
//         var id = $(this).attr("href");

//         // An offset to push the content down from the top.
//         var offset = 100;

//         // Our scroll target : the top position of the
//         // section that has the id referenced by our href.
//         var target = $(id).offset().top - offset;

//         // The magic...smooth scrollin' goodness.
//         $('html, body, .main-content').animate({scrollTop:target}, 10);

//         //prevent the page from jumping down to our section.
//         event.preventDefault();
//     });
// });


 
  
/******************************************************
		Tab steps add remove class for highlighted
*******************************************************/

// $(function() { 
  
//     $(".mutiselect__tabs .nav-item").click(function(){
// 	 $(this).toggleClass("show");
// 	});

// }); 



/******************************************************
	Side pane sub menu open close effect
****************************************************/

// $('.submenu__toggle').click(function(e) {
//   	e.preventDefault();
  
//     let $this = $(this);
  
//     if ($this.hasClass('show')) {
//         $this.removeClass('show');
//         $this.next().slideUp(250);
// 		//$this.parent().addClass('open__menu');
//     } else {
//         $this.parent().find('li').removeClass('show');
//        // $this.parent().find('li').removeClass('open__menu');
//         $this.parent().find('li .sub__nav').slideUp(250);
//         $this.toggleClass('show');
//         $this.next().slideToggle(250);
//     }

// });



/******************************************************
	Select option Custom Design with search box
****************************************************/

function create_custom_dropdowns() {
    $('.custom--select--search select').each(function (i, select) {
        if (!$(this).next().hasClass('dropdown-select')) {
            $(this).after('<div class="dropdown-select wide ' + ($(this).attr('class') || '') + '" tabindex="0"><span class="current"></span><div class="list"><ul></ul></div></div>');
            var dropdown = $(this).next();
            var options = $(select).find('option');
            var selected = $(this).find('option:selected');
            dropdown.find('.current').html(selected.data('display-text') || selected.text());
            options.each(function (j, o) {
                var display = $(o).data('display-text') || '';
                dropdown.find('ul').append('<li class="option ' + ($(o).is(':selected') ? 'selected' : '') + '" data-value="' + $(o).val() + '" data-display-text="' + display + '">' + $(o).text() + '</li>');
            });
        }
    });

    $('.dropdown-select ul').before('<div class="dd-search"><input type="text" id="txtSearchValue" class="dd-searchbox choices__input choices__input--cloned" spellcheck="false" role="textbox" aria-autocomplete="list" placeholder="" ><i></i></div>');
}



// Event listeners

// Open/close
// $(document).on('click', '.dropdown-select', function (event) {
//     if($(event.target).hasClass('dd-searchbox')){
//         return;
//     }
//     $('.dropdown-select').not($(this)).removeClass('open');
//     $(this).toggleClass('open');
//     if ($(this).hasClass('open')) {
//         $(this).find('.option').attr('tabindex', 0);
//         $(this).find('.selected').focus();
//     } else {
//         $(this).find('.option').removeAttr('tabindex');
//         $(this).focus();
//     }
// });

// Close when clicking outside
// $(document).on('click', function (event) {
//     if ($(event.target).closest('.dropdown-select').length === 0) {
//         $('.dropdown-select').removeClass('open');
//         $('.dropdown-select .option').removeAttr('tabindex');
//     }
//     event.stopPropagation();
// });

function filter(){
    var valThis = $('#txtSearchValue').val();
    $('.dropdown-select ul > li').each(function(){
     var text = $(this).text();
        (text.toLowerCase().indexOf(valThis.toLowerCase()) > -1) ? $(this).show() : $(this).hide();         
   });
};
// Search

// Option click
// $(document).on('click', '.dropdown-select .option', function (event) {
//     $(this).closest('.list').find('.selected').removeClass('selected');
//     $(this).addClass('selected');
//     var text = $(this).data('display-text') || $(this).text();
//     $(this).closest('.dropdown-select').find('.current').text(text);
//     $(this).closest('.dropdown-select').prev('select').val($(this).data('value')).trigger('change');
// });

// Keyboard events
// $(document).on('keydown', '.dropdown-select', function (event) {
//     var focused_option = $($(this).find('.list .option:focus')[0] || $(this).find('.list .option.selected')[0]);
//     // Space or Enter
//     //if (event.keyCode == 32 || event.keyCode == 13) {
//     if (event.keyCode == 13) {
//         if ($(this).hasClass('open')) {
//             focused_option.trigger('click');
//         } else {
//             $(this).trigger('click');
//         }
//         return false;
//         // Down
//     } else if (event.keyCode == 40) {
//         if (!$(this).hasClass('open')) {
//             $(this).trigger('click');
//         } else {
//             focused_option.next().focus();
//         }
//         return false;
//         // Up
//     } else if (event.keyCode == 38) {
//         if (!$(this).hasClass('open')) {
//             $(this).trigger('click');
//         } else {
//             var focused_option = $($(this).find('.list .option:focus')[0] || $(this).find('.list .option.selected')[0]);
//             focused_option.prev().focus();
//         }
//         return false;
//         // Esc
//     } else if (event.keyCode == 27) {
//         if ($(this).hasClass('open')) {
//             $(this).trigger('click');
//         }
//         return false;
//     }
// });

// $(document).ready(function () {
//     create_custom_dropdowns();
// });
  


/******************************************************
	Select option Custom Design
****************************************************/



var x, i, j, l, ll, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-select");
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  /*for each element, create a new DIV that will act as the selected item:*/
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /*for each element, create a new DIV that will contain the option list:*/
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 1; j < ll; j++) {
    /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener("click", function(e) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function(e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
}
function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x, y, i, xl, yl, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);




/*****************************
		choices
*****************************/
 
  if (document.getElementById('choices-skills')) {
      var skills = document.getElementById('choices-skills');
      const example = new Choices(skills, {
        delimiter: ',',
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true
      });
    }
	
	
/*****************************
		Sorting Tags
*****************************/
	 
  if (document.getElementById('sorting')) {
      var skills = document.getElementById('sorting');
      const example = new Choices(skills, {
        delimiter: ',',
        editItems: false,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true, 
		searchEnabled: false,
		searchChoices: true,
      });
    }



/********************************************
	Textarea auto height adjust
****************************************/

//  $(".question__block ul li textarea").each(function () {
//   this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
// }).on("input", function () {
//   this.style.height = "auto";
//   this.style.height = (this.scrollHeight) + "px";
// });
 

 

/*****************************
	Search Clear function
*****************************/


	// $('.has-clear input[type="text"]').on('input propertychange', function() {
	//   var $this = $(this);
	//   var visible = Boolean($this.val());
	//   $this.siblings('.form-control-clear').toggleClass('hidden', !visible);
	// }).trigger('propertychange');

	// $('.form-control-clear').click(function() {
	//   $(this).siblings('input[type="text"]').val('')
	// 	.trigger('propertychange').focus();
	// });
	
	
	
	// $('.clear-text input[type="text"]').on('input propertychange', function() {
	//   var $this = $(this);
	//   var visible = Boolean($this.val());
	//   $this.siblings('.form-clear').toggleClass('hidden', !visible);
	// }).trigger('propertychange');

	// $('.form-clear').click(function() {
	//   $(this).siblings('input[type="text"]').val('')
	// 	.trigger('propertychange').focus();
	// });



// $("#tagslist").on("click", "a", function(e){
//     e.preventDefault();
//     var $this = $(this).parent();
//     $this.addClass("select").siblings().removeClass("select");
//     $("#tagsvalue").val($this.data("value"));
// })



/*****************************
	scrollbar
*****************************/

 var win = navigator.platform.indexOf('Win') > -1;
    if (win && document.querySelector('#sidenav-scrollbar')) {
      var options = {
        damping: '0.5'
      }
      Scrollbar.init(document.querySelector('#sidenav-scrollbar'), options);
    }



/******************************************************
	Filter search
****************************************************/
/* 
$(function() { 
    $(".input__filter__tags").click(function(){
	 $(this).toggleClass("show");
	});

}); */

// $(".input__filter__tags").click(function(e) {
//   e.stopPropagation();
//   $("form").toggleClass('show');
// });

// $("html").click(function(e) {
//   if ($(e.target).closest('.input__filter__tags').length == 0)
//     $("form").removeClass('show');
// }); 


/******************************************************
	Tooltip hidden on click
****************************************************/

// $(function () {
//   $('[data-bs-toggle="tooltip"]').tooltip({
//       trigger : 'hover'
//   });
 
//   $('[data-bs-toggle="tooltip"]').on('click', function () {
//     $(this).tooltip('hide')
//   });
// });

/*****************************
	Sort div
*****************************/

 
// var $list = $('.question__block ul').sortable({
//   disabled:false,
//   update: function() {
//     var sortOrder = $('.question__block ul').sortable('toArray', {attribute: 'data-z'});
//   }
// });

// $('[data-z]').click(function(e){
//   var jTarget = $(e.target),
//       dir = jTarget.data('dir'),
//       jItem = $(e.currentTarget),
//       jItems = $('.question__block li'),
//       index = jItems.index(jItem);
  
//   switch (dir) {
//     case 'up':
//       if (index != 0) {
//         var item = $(this).detach().insertBefore(jItems[index - 1]);
//       }
//       break;
//     case 'down':
//       if (index != jItems.length - 1) {
//         var item = $(this).detach().insertAfter(jItems[index + 1]);
//       }
//       break;
//   }
//   var sortOrder = $('.question__block ul').sortable('toArray', {attribute: 'data-z'});
//   $('.info').text(sortOrder);
// });


/*****************************
	Dialog Modal
*****************************/

// $(".save__dialog").click(function(){
//   $("#dialog-box-modal").removeClass("show");
// });
// $(".save__dialog").click(function(){
//   $(".modal-backdrop").removeClass("show");
// });

	

/*****************************
		choices
*****************************/

 /* if (document.getElementById('choices-state')) {
      var element = document.getElementById('choices-state');
      const example = new Choices(element, {
        searchEnabled: true
      });
    };
	if (document.getElementById('choices-country')) {
      var element = document.getElementById('choices-country');
      const example = new Choices(element, {
        searchEnabled: true,
		placeholder: true,
		searchPlaceholderValue: null,
      });
    };
	if (document.getElementById('choices-countries')) {
      var element = document.getElementById('choices-countries');
      const example = new Choices(element, {
        searchEnabled: true
      });
    };
	if (document.getElementById('choices-work-type')) {
      var element = document.getElementById('choices-work-type');
      const example = new Choices(element, {
        searchEnabled: false,
		shouldSort: false
      });
    };
	if (document.getElementById('choices-work-type2')) {
      var element = document.getElementById('choices-work-type2');
      const example = new Choices(element, {
        searchEnabled: false,
		shouldSort: false
      });
    };
	if (document.getElementById('choices-level')) {
      var element = document.getElementById('choices-level');
      const example = new Choices(element, {
        searchEnabled: true,
		shouldSort: false
      });
    };
	if (document.getElementById('education-level')) {
      var element = document.getElementById('education-level');
      const example = new Choices(element, {
        searchEnabled: true,
		shouldSort: false
      });
    };
	if (document.getElementById('sort')) {
      var element = document.getElementById('sort');
      const example = new Choices(element, {
        searchEnabled: false,
		shouldSort: false
      });
    };
	if (document.getElementById('choices-department')) {
      var element = document.getElementById('choices-department');
      const example = new Choices(element, {
        searchEnabled: true,
		shouldSort: false
      });
    };
	if (document.getElementById('choices-industry')) {
      var element = document.getElementById('choices-industry');
      const example = new Choices(element, {
        searchEnabled: true,
		shouldSort: false
      });
    };
	if (document.getElementById('filter-search')) {
      var element = document.getElementById('filter-search');
      const example = new Choices(element, {
        searchEnabled: true,
		shouldSort: false
      });
    };
	 if (document.getElementById('choices-language')) {
      var language = document.getElementById('choices-language');
      const example = new Choices(language);
    }; */
	 /* if (document.getElementById('filter-search')) {
      var language = document.getElementById('filter-search');
      const example = new Choices(language);
    }; */
	
	
/*****************************
		datetimepicker
*****************************/
	
	 if (document.querySelector('.datetimepicker')) {
      flatpickr('.datetimepicker', {
        allowInput: true
      }); // flatpickr
    }
	
	
/*******************************************************
		Add active class to the current button
******************************************************/	
	
	// var header = document.getElementById("groupselected");
	// var btns = header.getElementsByClassName("groupin");
	// for (var i = 0; i < btns.length; i++) {
	//   btns[i].addEventListener("click", function() {
	//   var current = document.getElementsByClassName("active");
	//   current[0].className = current[0].className.replace(" active", "");
	//   this.className += " active";
	//   });
	// }


/*****************************
	smothscroll
*****************************/



 // Initialize the plugin

// const ps = new PerfectScrollbar('#smothscroll', {
// 	wheelSpeed: 2,
// 	wheelPropagation: true,
// 	minScrollbarLength: 10,
// 	suppressScrollX: false,
// 	suppressScrollY: false,
// 	swipeEasing: false,
// 	scrollXMarginOffset: 0,
// 	scrollYMarginOffset: 0

// });





/*****************************
	Sub Menu Toggle 
*****************************/


